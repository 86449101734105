
const state = () => ({
    vds: []
});

const getters = {
    vds: state => state.vds,
};

const mutations = {
    SET(state, obj){
        state = obj;
    },
    SET_VDS(state, obj){
        state.vds = obj;
    },
    ADD_VD(state, obj){
        state.vds.push(obj);
    },
    REMOVE_VD(state, obj){
        state.vds.splice(state.vds.indexOf(obj), 1);
    }
};

const actions = {
    set({ commit }, obj){
        commit("SET", obj);
    },
    setVDS({ commit }, obj){
        commit("SET_VDS", obj);
    },
    addVD({ commit }, obj){
        commit("ADD_VD", obj);
    },
    removeVD({ commit }, obj){
        commit("REMOVE_VD", obj);
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
