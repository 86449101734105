import Vue from 'vue';
import Router from 'vue-router';
import userService from "../services/userService";
import store from "../store/store";

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');
const MyResources = () => import('@/views/MyResources');
const FAQ = () => import('@/views/Faq');

// Content
const ContentLinkManagement = () => import('@/views/content/linkManagement/List');
const ContentLinkManagementNew = () => import('@/views/content/linkManagement/New');
const ContentLinkManagementEdit = () => import('@/views/content/linkManagement/Edit');
const ContentAccountManagement = () => import('@/views/content/accountManagement/List');
const ContentAccountManagementNew = () => import('@/views/content/accountManagement/New');
const ContentAccountManagementEdit = () => import('@/views/content/accountManagement/Edit');
const ContentAccountManagementDetail = () => import('@/views/content/accountManagement/Detail');
const ContentFaqManagement = () => import('@/views/content/faqManagement/List');
const ContentFaqManagementNew = () => import('@/views/content/faqManagement/New');
const ContentFaqManagementEdit = () => import('@/views/content/faqManagement/Edit');
const ContentFaqManagementDetail = () => import('@/views/content/faqManagement/Detail');
const ContentDownloadManagement = () => import('@/views/content/downloadManagement/List');
const ContentDownloadManagementNew = () => import('@/views/content/downloadManagement/New');
const ContentDownloadManagementEdit = () => import('@/views/content/downloadManagement/Edit');
const ContentDownloadManagementDetail = () => import('@/views/content/downloadManagement/Detail');


// VDI
const VdiPools = () => import('@/views/vdi/Pools/List');
const VdiPoolsNew = () => import('@/views/vdi/Pools/New');
const VdiPoolsEdit = () => import('@/views/vdi/Pools/Edit');
const VdiPoolsDetail = () => import('@/views/vdi/Pools/Detail');
const VdiPoolsSettings = () => import('@/views/vdi/Pools/Settings');
const VdiApps = () => import('@/views/vdi/Apps/List');
const VdiAppsNew = () => import('@/views/vdi/Apps/New');
const VdiAppsDetail = () => import('@/views/vdi/Apps/Detail');
const VdiAppsEdit = () => import('@/views/vdi/Apps/Edit');
const VdiAppsSettings = () => import('@/views/vdi/Apps/Settings');
const VdiVM = () => import('@/views/vdi/VM/List');
const VdiRDSH = () => import('@/views/vdi/RDSH/List');
const VdiRdshNew = () => import('@/views/vdi/RDSH/New');
const VdiRdshDetail = () => import('@/views/vdi/RDSH/Detail');
const VdiRdshEdit = () => import('@/views/vdi/RDSH/Edit');
const VdiRdshSettings = () => import('@/views/vdi/RDSH/Settings');
const VdiImages = () => import('@/views/vdi/Images/List');
const VdiImagesNew = () => import('@/views/vdi/Images/New');
const VdiImagesDetail = () => import('@/views/vdi/Images/Detail');
const VdiImagesEdit = () => import('@/views/vdi/Images/Edit');
const VdiDatastores = () => import('@/views/vdi/Datastores/List');
const VdiDatastoresNew = () => import('@/views/vdi/Datastores/New');
const VdiDatastoresDetail = () => import('@/views/vdi/Datastores/Detail');
const VdiDatastoresEdit = () => import('@/views/vdi/Datastores/Edit');


// Admin
const AdminKeyManagement = () => import('@/views/admin/KeyManagement/List');
const AdminKeyManagementNew = () => import('@/views/admin/KeyManagement/New');
const AdminUser = () => import('@/views/admin/user/List');
const AdminUserDetail = () => import('@/views/admin/user/Detail');
const AdminLogs = () => import('@/views/admin/logs/List');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Login = () => import('@/views/auth/Login');


Vue.use(Router);

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/myResources',
            name: 'Home',
            component: TheContainer,
            meta: {
                label: 'dashboard',
                auth: true
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        label: 'dashboard',
                        auth: true
                    }
                },
                {
                    path: 'myResources',
                    name: 'MyResources',
                    component: MyResources,
                    meta: {
                        label: 'myResources',
                        auth: true
                    }
                },
                {
                    path: 'faq',
                    name: 'FAQ',
                    component: FAQ,
                    meta: {
                        label: 'faq',
                        auth: true
                    }
                },
                {
                    path: 'content',
                    redirect: '/content/faqManagement/list',
                    name: 'Content',
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    meta: {
                        label: 'content.label',
                        auth: true
                    },
                    children: [
                        {
                            path: 'faqManagement',
                            name: 'ContentFaqManagement',
                            redirect: '/content/faqManagement/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'content.faqManagement.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'ContentFaqManagementList',
                                    component: ContentFaqManagement,
                                    meta: {
                                        label: 'content.faqManagement.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'ContentFaqManagementNew',
                                    component: ContentFaqManagementNew,
                                    meta: {
                                        label: 'content.faqManagement.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    props: true,
                                    name: 'ContentFaqManagementEdit',
                                    component: ContentFaqManagementEdit,
                                    meta: {
                                        label: 'content.faqManagement.edit',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'ContentFaqManagementDetail',
                                    component: ContentFaqManagementDetail,
                                    meta: {
                                        label: 'content.faqManagement.details',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'linkManagement',
                            name: 'ContentLinkManagement',
                            redirect: '/content/linkManagement/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'content.linkManagement.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'ContentLinkManagementList',
                                    component: ContentLinkManagement,
                                    meta: {
                                        label: 'content.linkManagement.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'ContentLinkManagementNew',
                                    component: ContentLinkManagementNew,
                                    meta: {
                                        label: 'content.linkManagement.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    props: true,
                                    name: 'ContentLinkManagementEdit',
                                    component: ContentLinkManagementEdit,
                                    meta: {
                                        label: 'content.linkManagement.edit',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'accountManagement',
                            name: 'ContentAccountManagement',
                            redirect: '/content/accountManagement/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'content.accountManagement.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'ContentAccountManagementList',
                                    component: ContentAccountManagement,
                                    meta: {
                                        label: 'content.accountManagement.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'ContentAccountManagementNew',
                                    component: ContentAccountManagementNew,
                                    meta: {
                                        label: 'content.accountManagement.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    props: true,
                                    name: 'ContentAccountManagementEdit',
                                    component: ContentAccountManagementEdit,
                                    meta: {
                                        label: 'content.accountManagement.edit',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'ContentAccountManagementDetail',
                                    component: ContentAccountManagementDetail,
                                    meta: {
                                        label: 'content.accountManagement.details',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'downloadManagement',
                            name: 'ContentDownloadManagement',
                            redirect: '/content/downloadManagement/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'content.downloadManagement.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'ContentDownloadManagementList',
                                    component: ContentDownloadManagement,
                                    meta: {
                                        label: 'content.downloadManagement.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'ContentDownloadManagementNew',
                                    component: ContentDownloadManagementNew,
                                    meta: {
                                        label: 'content.downloadManagement.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    props: true,
                                    name: 'ContentDownloadManagementEdit',
                                    component: ContentDownloadManagementEdit,
                                    meta: {
                                        label: 'content.downloadManagement.edit',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'ContentDownloadManagementDetail',
                                    component: ContentDownloadManagementDetail,
                                    meta: {
                                        label: 'content.downloadManagement.details',
                                        auth: true
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'vdi',
                    redirect: '/vdi/pools/list',
                    name: 'Vdi',
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    meta: {
                        label: 'vdi.label',
                        auth: true
                    },
                    children: [
                        {
                            path: 'pools',
                            redirect: 'pools/list',
                            name: 'VdiPoolsTree',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.pools.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiPools',
                                    component: VdiPools,
                                    meta: {
                                        label: 'vdi.pools.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'VdiPoolsNew',
                                    component: VdiPoolsNew,
                                    meta: {
                                        label: 'vdi.pools.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    name: 'VdiPoolsDetail',
                                    props: true,
                                    component: VdiPoolsDetail,
                                    meta: {
                                        label: 'vdi.pools.details',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'settings',
                                    name: 'VdiPoolsSettings',
                                    props: true,
                                    component: VdiPoolsSettings,
                                    meta: {
                                        label: 'vdi.pools.settings',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    name: 'VdiPoolsEdit',
                                    props: true,
                                    component: VdiPoolsEdit,
                                    meta: {
                                        label: 'vdi.pools.edit',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'apps',
                            name: 'VdiApps',
                            redirect: 'apps/list',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.appPools.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiAppList',
                                    component: VdiApps,
                                    meta: {
                                        label: 'vdi.appPools.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'VdiAppNew',
                                    component: VdiAppsNew,
                                    meta: {
                                        label: 'vdi.appPools.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    name: 'VdiAppDetail',
                                    props: true,
                                    component: VdiAppsDetail,
                                    meta: {
                                        label: 'vdi.appPools.details',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    name: 'VdiAppEdit',
                                    props: true,
                                    component: VdiAppsEdit,
                                    meta: {
                                        label: 'vdi.appPools.edit',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'settings',
                                    name: 'VdiAppSettings',
                                    props: true,
                                    component: VdiAppsSettings,
                                    meta: {
                                        label: 'vdi.appPools.settings',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'rdsh',
                            redirect: 'rdsh/list',
                            name: 'VdiRDSHTree',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.rdsh.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiRDSH',
                                    component: VdiRDSH,
                                    meta: {
                                        label: 'vdi.rdsh.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'VdiRDSHNew',
                                    component: VdiRdshNew,
                                    meta: {
                                        label: 'vdi.rdsh.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'settings',
                                    name: 'VdiRDSHSettings',
                                    component: VdiRdshSettings,
                                    meta: {
                                        label: 'vdi.rdsh.settings',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'VdiRDSHDetail',
                                    component: VdiRdshDetail,
                                    meta: {
                                        label: 'vdi.rdsh.details',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    name: 'VdiRDSHEdit',
                                    props: true,
                                    component: VdiRdshEdit,
                                    meta: {
                                        label: 'vdi.rdsh.edit',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'images',
                            redirect: 'images/list',
                            name: 'VdiImagesTree',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.images.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiImages',
                                    component: VdiImages,
                                    meta: {
                                        label: 'vdi.images.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'VdiImagesNew',
                                    component: VdiImagesNew,
                                    meta: {
                                        label: 'vdi.images.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'VdiImagesDetail',
                                    component: VdiImagesDetail,
                                    meta: {
                                        label: 'vdi.images.details',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    name: 'VdiImagesEdit',
                                    props: true,
                                    component: VdiImagesEdit,
                                    meta: {
                                        label: 'vdi.images.edit',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'datastores',
                            redirect: 'datastores/list',
                            name: 'VdiDatastoresTree',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.datastores.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiDatastores',
                                    component: VdiDatastores,
                                    meta: {
                                        label: 'vdi.datastores.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'VdiDatastoresNew',
                                    component: VdiDatastoresNew,
                                    meta: {
                                        label: 'vdi.datastores.new',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'detail/:id',
                                    props: true,
                                    name: 'VdiDatastoresDetail',
                                    component: VdiDatastoresDetail,
                                    meta: {
                                        label: 'vdi.datastores.details',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'edit/:id',
                                    name: 'VdiDatastoresEdit',
                                    props: true,
                                    component: VdiDatastoresEdit,
                                    meta: {
                                        label: 'vdi.datastores.edit',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'vm',
                            redirect: 'vm/list',
                            name: 'VdiVMTree',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            meta: {
                                label: 'vdi.vm.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'VdiVM',
                                    component: VdiVM,
                                    meta: {
                                        label: 'vdi.vm.list',
                                        auth: true
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'admin',
                    redirect: '/admin/keyManagement/list',
                    name: 'Admin',
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    meta: {
                        label: 'admin.label',
                        auth: true
                    },
                    children: [
                        {
                            path: 'keyManagement',
                            name: 'AdminKeyManagement',
                            redirect: '/admin/keyManagement/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'admin.keyManagement.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'AdminKeyManagementList',
                                    component: AdminKeyManagement,
                                    meta: {
                                        label: 'admin.keyManagement.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: 'new',
                                    name: 'AdminKeyManagementNew',
                                    component: AdminKeyManagementNew,
                                    meta: {
                                        label: 'admin.keyManagement.new',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'user',
                            name: 'AdminUser',
                            redirect: '/admin/user/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'admin.user.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'AdminUserList',
                                    component: AdminUser,
                                    meta: {
                                        label: 'admin.user.list',
                                        auth: true
                                    }
                                },
                                {
                                    path: ':id',
                                    props: true,
                                    name: 'AdminUserDetail',
                                    component: AdminUserDetail,
                                    meta: {
                                        label: 'admin.user.details',
                                        auth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'logs',
                            name: 'AdminLogs',
                            redirect: '/admin/logs/list',
                            component: { render(c) {return c('router-view');} },
                            meta: {
                                label: 'admin.logs.label',
                                auth: true
                            },
                            children: [
                                {
                                    path: 'list',
                                    name: 'AdminLogsList',
                                    component: AdminLogs,
                                    meta: {
                                        label: 'admin.logs.list',
                                        auth: true
                                    }
                                },
                            ]
                        },
        
                    ]
                },

            ]
        },
        {
            path: '/',
            redirect: 'login',
            name: 'PreLogin',
            component: {
                render(c) {
                    return c('router-view');
                }
            },
            meta: {
                label: 'login',
                auth: false
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login,
                    meta: {
                        auth: false
                    }
                }
            ]
        },
        {
            path: '*',
            name: 'Pages',
            component: Page404
        }
    ];
}


router.beforeEach((to, from, next) => {
    //let guestPages = ['/login', '/resetPassword', '/resetPasswordCode'];
    if(process.env.NODE_ENV === 'development') console.log("Router: ", to.path); // DEBUG
    if (!('auth' in to.meta)) {
        if(process.env.NODE_ENV === 'development') console.log("auth not provided"); // DEBUG
        next();
    } else {
        if (to.matched.some(record => record.meta.auth)) {
            if(process.env.NODE_ENV === 'development') console.log("auth needed", userService.isLoggedIn()); // DEBUG
            if (userService.isLoggedIn()) {
                if(process.env.NODE_ENV === 'development') console.log("auth success"); // DEBUG
                if (store.state.user.changePassword && !to.path.match(/^\/updatePassword/)) {
                    next('/updatePassword');
                } else {
                    next();
                }
            } else {
                if(process.env.NODE_ENV === 'development') console.log("auth failed, redirect to login"); // DEBUG
                sessionStorage.setItem('redirectPath', to.path);
                store.dispatch("user/logout");
                localStorage.removeItem("token")
                next('/login');
            }
        } else {
            if(process.env.NODE_ENV === 'development') console.log("no auth needed or only auth", userService.isLoggedIn()); // DEBUG
            if (userService.isLoggedIn()) {
                next();
            } else {
                if (!to.path.match(/^\/(login|forgetPassword)/)) {
                    if(process.env.NODE_ENV === 'development') console.log('redirect to login'); // DEBUG
                    next('/login');
                } else {
                    next();
                }
            }
        }
    }
});
/*
router.afterEach((to) => {
    // Complete the animation of the route progress bar.
    Vue.nextTick(() => {
        let title = '';
        if (to.meta.label) {
            title = to.meta.label + ' - ';
        }
        document.title = title + process.env.VUE_APP_DEFAULT_SITE_TITLE;
    });
});*/

export default router;
