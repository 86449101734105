import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import {iconsSet as icons} from './assets/icons/icons.js';
import store from './store/store';


/**********************************
 * i18n
 **********************************/
import i18n from './i18n';


/**********************************
 * VeeValidate v3
 **********************************/

import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import veeDE from "vee-validate/dist/locale/de.json";
import veeEN from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("en", veeEN);
localize("de", veeDE);


// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

/**********************************
 * Moment JS
 **********************************/

import moment from 'moment';
import VueMomentJS from 'vue-momentjs';

Vue.use(VueMomentJS, moment);

/**********************************
 * Toastr
 **********************************/

import toastr from 'vue-toastr';

Vue.use(toastr, {
    defaultPosition: 'toast-top-center'
});

/**********************************
 * VueSelect
 **********************************/

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect);

/**********************************
 * vue-codeditor
 **********************************/

import vueCodeditor from "vue-codeditor";

Vue.component(vueCodeditor)

/**********************************
 * FontAwesome
 **********************************/

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faFontAwesome} from '@fortawesome/free-brands-svg-icons';

library.add(faFontAwesome);
library.add(fas);
library.add(far);
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);

/**********************************
 * errorHandler
 **********************************/
import {errorHandler} from './services/errorHandler';

Vue.mixin(errorHandler);


/**********************************
 * Bootstrap
 **********************************/
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import '../scss/custom.scss';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

new Vue({
    el: '#app',
    i18n,
    router,
    store,
    icons,
    template: '<App/>',
    mounted() {
        let lang = navigator.language === 'de' ? 'de' : 'en';
        if (localStorage.getItem("locale")) {
            lang = localStorage.getItem("locale") === 'de' ? 'de' : 'en';
        }
        this.$i18n.locale = lang;
        localize(lang);
        console.log('your browser language:', navigator.language, ', selected language for you:', lang);
    },
    components: {
        App
    }
});
