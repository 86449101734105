export const errorHandler = {
    methods: {
        /**
         * Creates a message toast based on the error response from axios
         * @example
         * errorHandler(error, [
         *  {
         *      status: 404,
         *      message: 'Ups custom 1',
         *      title: 'Custom title', // optional
         *      extend: [ // optional
         *          {
         *              code: 100, // musst exists as errorCode attr in body for specific error code
         *              message: 'Override -Ups custom 1- message, as example: password failed'
         *          }
         *      ]
         *  }, { status: 403, message: 'Custom message'}])
         * @param {object} error Error response object from axios
         * @param {array} customErrorMessages Custom messages
         * @param {boolean} hidden=true prevents the creation of a message toast
         */
        errorHandler(error, customErrorMessages, hidden= true) {
            let messages = [
                {
                    status: 400,
                    message: this.$t('errorMessages.statusCode.400.message'),
                    title: this.$t('errorMessages.statusCode.400.title'),
                    extend: [
                        {
                            code: 100,
                            message: this.$t('errorMessages.statusCode.400.extend.100')
                        },
                        {
                            code: 200,
                            message: this.$t('errorMessages.statusCode.400.extend.200')
                        },
                    ],
                },
                {
                    status: 401,
                    message: this.$t('errorMessages.statusCode.401.message'),
                    title: this.$t('errorMessages.statusCode.401.title'),
                    call: () => {
                        this.$store.dispatch('user/logout');
                        this.$router.push('/login').then();
                    }
                },
                {
                    status: 403,
                    message: this.$t('errorMessages.statusCode.403.message'),
                    title: this.$t('errorMessages.statusCode.403.title'),
                },
                {
                    status: 404,
                    message: this.$t('errorMessages.statusCode.404.message'),
                    title: this.$t('errorMessages.statusCode.404.title'),
                },
                {
                    status: 500,
                    message: this.$t('errorMessages.statusCode.500.message'),
                    title: this.$t('errorMessages.statusCode.500.title'),
                },
            ];
            if(process.env.NODE_ENV === "development") console.log('%c%s%s', 'color: red', `Etwas ist schief gelaufen...`, error);

            if (customErrorMessages) {
                if (!Array.isArray(customErrorMessages)) {
                    customErrorMessages = [customErrorMessages];
                }
                for(let i = 0, l = messages.length; i < l; i++) {
                    for(let j = 0, ll = customErrorMessages.length; j < ll; j++) {
                        if(messages[i].status === customErrorMessages[j].status) {
                            messages.splice(i, 1, customErrorMessages[j]);
                            break;
                        }
                    }
                }
            }

            if (error instanceof Error && /Network Error/.test(error)) {
                this.$toastr.e(this.$t('errorMessages.networkError.message'), this.$t('errorMessages.networkError.title'));
            } else if (error && error.response && error.response.status) {
                let status = error.response.status;
                for (let i = 0; i < messages.length; i++) {
                    if (messages[i].status === status) {
                        if(messages[i].extend && error.response.data && error.response.data.errorCode && messages[i].extend.some(ext => ext.code === parseInt(error.response.data.errorCode))) {
                            this.$toastr.e(messages[i].extend.find(ext => ext.code === error.response.data.errorCode).message);
                        } else {
                            if (messages[i].title && messages[i].message) {
                                if(!hidden) {
                                    this.$toastr.e(messages[i].message, messages[i].title);
                                } else {
                                    console.log('%c%s', 'color: red', messages[i].message);
                                }
                            } else if (messages[i].message) {
                                if(!hidden) {
                                    this.$toastr.e(messages[i].message);
                                } else {
                                    console.log('%c%s', 'color: red', messages[i].message);
                                }
                            }
                        }
                        if (typeof messages[i].call === "function") {
                            messages[i].call();
                        }
                    }
                }
            } else {
                if (!(error >= 200 && error <= 203)) {
                    if (error !== 0) {
                        if(!hidden) {
                            this.$toastr.e(this.$t('errorMessages.default.message'), this.$t('errorMessages.default.title'));
                        } else {
                            console.error(error);
                        }
                    }
                }
            }
        }
    }
}
