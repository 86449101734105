import axios from "axios";
import store from "@/store/store"

/**
 * Create axios instance with default options
 * @returns {AxiosInstance}
 */
export default () => {
    let headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': `${location.protocol}//${location.host}`,
    }
    if(process.env.NODE_ENV === "development"){
        headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // for develop
        }
    }

    if(store.getters["user/token"]) {
        headers['Authorization'] = `Bearer ${store.getters["user/token"]}`;
    }

    return axios.create({
        baseURL: store.getters.getApi ? store.getters.getApi : process.env.VUE_APP_API,
        withCredentials: false,
        headers: headers
    });
}
