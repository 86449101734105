import jwt from "jsonwebtoken";

const state = () => ({
    token: null,
    tokenExpiresAt: '',
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    userName: null,
    activated: false,
    createdAt: false,
    updatedAt: false,
    navbar: null,
    roles: null,
});

const getters = {
    all: state => state,
    id: state => state.id,
    expiresAt: state => state.tokenExpiresAt,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    userName: state => state.userName,
    token: state => state.token,
    navbar: state => state.navbar,
    roles: state => state.roles,
};

const mutations = {
    SET_USER(state, userObj){
        state.id = userObj.id;
        state.email = userObj.email;
        state.userName = userObj.userName;
        state.firstName = userObj.firstName;
        state.lastName = userObj.lastName;
        state.activated = userObj.activated;
        state.createdAt = userObj.createdAt;
        state.updatedAt = userObj.updatedAt;
        state.navbar = userObj.navbar;
        state.roles = userObj.roles;
    },
    SET_TOKEN(state, userObj){
        state.token = userObj.token;
        state.tokenExpiresAt = new Date(jwt.decode(userObj.token).exp * 1000);
    },
    LOGOUT(storeState){
        console.log("Logout");
        storeState = Object.assign(storeState, state());
    }
};

const actions = {
    setUser({ commit }, userObj){
        commit("SET_USER", userObj);
    },
    setToken({ commit }, userObj){
        commit("SET_TOKEN", userObj);
    },
    logout({ commit }){
        commit("LOGOUT");
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
