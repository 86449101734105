import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const sls = new SecureLS({isCompression: false});
import user from "@/store/modules/user";
import resources from "@/store/modules/resources";



Vue.use(Vuex);


const getDefaultState = () => {
    return {
        baseApiUrl: process.env.VUE_APP_API,
        sidebarShow: 'responsive',
        sidebarMinimize: false
    };
};

const getters = {
    isLoggedIn: state => state.user.token,
    getApi: state => state.baseApiUrl
};

const mutations = {
    setApi(state, url){
        state.baseApiUrl = url;
    },
    toggleSidebarPool(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
        state.sidebarShow = sidebarOpened ? false : 'responsive';
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
        state.sidebarShow = sidebarClosed ? true : 'responsive';
    },
    set(state, [variable, value]) {
        state[variable] = value;
    }
};

const actions = {
    setApi({ commit }, url) {
        commit('setApi', url)
    }
};

export default new Vuex.Store({
    state: getDefaultState,
    mutations,
    actions,
    getters,
    plugins: [
        createPersistedState({
            key: "vdi",
            path: ["user"],
            storage: {
                getItem: key => sls.get(key),
                setItem: (key, value) => sls.set(key, value),
                removeItem: key => sls.remove(key)
            }
        }),
        /*createPersistedState({ // TODO: vor Produktivsetzung entfernen!
            key: "clean",
            path: ["user"]
        })*/
    ],
    modules: {
        user,
        resources
    }
});
