import api from "./api";
import store from "@/store/store";
import jwt from "jsonwebtoken";

export default {
    api: 'users',
    apiLogin: 'authorization/login',
    /**
     * Is the user logged in
     * @returns {boolean}
     */
    isLoggedIn() {
        const token = localStorage.getItem("token");
        if (token) {
            const expiresAt = new Date(jwt.decode(token).exp * 1000);
            if (!!expiresAt) {
                return expiresAt > new Date();
            }
        }
        return false;
    },
    /**
     * Triggers a login request and, if the login is successful, retrieves all important data from the backend before the user is forwarded. If there is an error in the data query, the login is aborted.
     * @param {object} self Vue-Instance
     */
    login(self) {
        api().post(this.apiLogin, {userName: self.form.username, password: self.form.password, extended: self.remember})
        .then((response) => {
            self.sync = true;
            const user = Object.assign({}, response.data);
            user.token = response.headers.authorization.split(' ')[1];

            if(process.env.NODE_ENV === 'development') console.log('Gültig bis: ', new Date(jwt.decode(user.token).exp * 1000));

            store.dispatch("user/setToken", user).then();
            localStorage.setItem("token", user.token);
            localStorage.setItem("locale", self.$i18n.locale);
            let redirectPath = sessionStorage.getItem("redirectPath");
            if (!redirectPath) {
                redirectPath = "/myResources";
            }

            this.get(user.id).then(response => {
                self.loading = false;
                store.dispatch("user/setUser", response.data).then();
                self.$router.push({path: redirectPath}).catch();
            }).catch(error => {
                self.errorHandler(error)
                self.loading = false;
                // remove all local storage entries
                this.logout(self, false);
            });
        })
        .catch((response) => {
            self.loading = false;
            self.disable = true;
            self.seconds = 3;
            let int = setInterval(() => { self.seconds--; }, 1000);
            setTimeout(() => { clearInterval(int); self.disable = false; }, (self.seconds * 1000));
            self.errorHandler(response, {status: 400, message: self.$t('pages.login.messages.error.usernameOrPasswordWrong')});
        });
    },
    /**
     * Get all users from DB
     * @returns {Promise}
     */
    list() {
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                //store.dispatch("users/setAll", response.data);
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get user by id
     * @param {string|number} id User ID
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + "/" + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update user object, send request to backend
     * @param {string|number} id User ID
     * @param {object} data Object with user Attributes
     * @returns {Promise}
     */
    editUser(id, data) {
        return new Promise((resolve, reject) => {
            api().put(this.api + "/" + id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Remove one or more users
     * @param {array} data Array of user ids to remove
     * @returns {Promise}
     */
    removeUser(data) {
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data }).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Logs out the current user and redirects them back to the login if desired
     * @param {object} self Vue-Instance
     * @param {boolean} redirect=true should the user be guided to the login?
     */
    logout(self, redirect = true) {
        store.dispatch("user/logout").then();
        localStorage.removeItem("token")
        localStorage.removeItem("clean")
        if(redirect) self.$router.push({path: "/login"}).catch();
        localStorage.removeItem("vdi")
        localStorage.removeItem("secure__ls__metadata")
    },

   /**
     * Get resource for user
     * @param {string|number} id User ID
     * @param {string} resourceId ResourceId
     * @returns {Promise}
     */
    getResource(id, resourceId) {
        return new Promise((resolve, reject) => {
            api().get(`${this.api}/${id}/resource/${resourceId}`).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },

    /**
     * Add resource to user
     * @param {string|number} id User ID
     * @param {object} data Resource object
     * @returns {Promise}
     */
    addResource(id, data) {
        return new Promise((resolve, reject) => {
            api().post(this.api + '/' + id + '/resource', data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },

    /**
     * Update user resource
     * @param {string|number} id User ID
     * @param {object} data Resource object
     * @returns {Promise}
     */
    editResource(id, data){
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + id + '/resource', data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },

    /**
     * Delete user resource
     * @param {string|number} id User ID
     * @param {array} data Array of resource ids to delete from user
     * @returns {Promise}
     */
    deleteResource(id, data){
        return new Promise((resolve, reject) => {
            api().delete(this.api + '/' + id + '/resources', { data: data }).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
